<div
  class="fixed-top d-print-none d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
  <h5 class="my-0 mr-md-auto font-weight-normal" routerLink="/" style="cursor: pointer;">
    Cothema NLP
    <sub *ngIf="featureLevelService.currentLevel === featureLevelService.levelBeta">beta</sub>
    <sub *ngIf="featureLevelService.currentLevel === featureLevelService.levelDev">dev</sub>
  </h5>
  <nav class="my-2 my-md-0 mr-md-3">
  </nav>
  <a class="btn btn-outline-primary" routerLink="/sign-in">
    <fa-icon [icon]="faUser"></fa-icon>&nbsp;
    <ng-template #signIn>Přihlášení</ng-template>
    <ng-container *ngIf="(authService.user$ | async) as user; else signIn">{{user.displayName}}</ng-container>
  </a>
  <select (change)="onChangeLang()" [(ngModel)]="lang" class="form-control"
          style="display: inline-block;width: auto;margin-left: 0.5em;">
    <option [ngValue]="'cs'">CZ</option>
    <option [ngValue]="'en'">EN</option>
  </select>
</div>

<div class="container">
  <router-outlet></router-outlet>
</div>

<div class="d-print-none container">
  <footer class="pt-4 my-md-5 pt-md-5 border-top">
    <div class="row">
      <div class="col-12 col-md">
        <small class="text-muted">Vytvořeno s &hearts;</small><br/>
        <a class="text-muted" routerLink="/contacts">Cothema s.r.o.</a>
        <small class="d-block mb-3 text-muted">© 2020</small>
        <small class="text-muted">Oceníme případný zájem o spolupráci nebo jakoukoliv formu podpory.</small>
      </div>
      <div class="col-6 col-md">
        <ul class="list-unstyled text-small">
          <li>
            <a class="text-muted" routerLink="/api">API pro vývojáře</a>
          </li>
          <li *ngIf="featureLevelService.currentLevel <= featureLevelService.levelDev">
            <a class="text-muted" routerLink="/licenses">
              Licence
            </a>
          </li>
          <li>
            <a class="text-muted" href="https://github.com/cothema/nlp20-web" target="_blank">
              <fa-icon [icon]="faGithub"></fa-icon>&nbsp; GitHub
            </a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <ul class="list-unstyled text-small">
          <li>
            <a *ngIf="lang === 'cs' && featureLevelService.currentLevel <= featureLevelService.levelDev"
               class="text-muted" routerLink="/learn">
              Učivo ZŠ
            </a>
          </li>
          <li>
            <a *ngIf="featureLevelService.currentLevel <= featureLevelService.levelDev"
               class="text-muted"
               routerLink="/dictionary">
              Slovník
            </a>
          </li>
          <li>
            <a class="text-muted" routerLink="/links">
              Užitečné odkazy
            </a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <ul class="list-unstyled text-small">
          <li>
            <a class="text-muted" routerLink="/about">O projektu</a>
          </li>
          <li>
            <a class="text-muted" routerLink="/contacts">Kontakty</a>
          </li>
          <li>
            <a class="text-muted" routerLink="/terms">Podmínky použití</a>
          </li>
          <li>
            <a class="text-muted" fragment="privacy" routerLink="/terms">Ochrana osobních údajů</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>
