<div *ngFor="let sentence of sentences" class="card m-3">
  <div class="card-body text-center font-weight-bold">
    <app-sentence-words *ngIf="sentence.fragment.words" [sentence]="sentence"></app-sentence-words>
  </div>

  <div class="card-footer text-center">
    <div class="row">
      <div class="col-md-4">
        Typ věty: {{sentence.fragment.sentenceType}}
      </div>
      <div class="col-md-4">
        Počet slov: {{sentence.fragment.words?.length}}
      </div>
      <div class="col-md-4">
        Počet znaků: {{sentence.fragment.string.length}}
      </div>
    </div>
  </div>
</div>
