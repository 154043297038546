export class ColorGenerator {
  static getHappyColors() {
    return [
      '#01BEFE',
      '#FFDD00',
      '#FF006D',
      '#FF7D00',
      '#ADFF02',
      '#8F00FF'
    ];
  }
}
