<div class="card">
  <div class="card-header text-center">
    <span [innerHTML]="box['icon']"></span>
    {{box['title']}}
  </div>
  <div class="card-body text-center">
    <div [ngSwitch]="box['type']" style="font-size:1.8em">
      <div *ngSwitchCase="'type_html'" [innerHTML]="box['text']" [mathjax]></div>
      <div *ngSwitchCase="'type_latex'" [mathjax]>
        \( {{box['text']}} \)
      </div>
      <div *ngSwitchDefault>
        {{box['text']}}
      </div>

      <div *ngIf="box['steps'].length" class="pt-2">
        <button (click)="onShowSteps()" class="btn btn-outline-dark btn-sm">
          <fa-icon [icon]="faStream"></fa-icon>&nbsp; Zobrazit postup řešení
        </button>
        &nbsp;
        <button (click)="onContinueByResult()" class="btn btn-outline-dark btn-sm">
          <fa-icon [icon]="faCaretRight"></fa-icon>&nbsp; Pokračovat výpočtem
        </button>
      </div>
    </div>
    <div *ngIf="box['steps']" class="text-left">
      <ol>
        <li *ngFor="let step of box['steps'];">
          <div class="step" style="display: inline-block">
            <h4 *ngIf="step['title']">{{step['title']}}</h4>
            <h5 *ngIf="step['description']" [innerHTML]="step['description']" [mathjax]></h5>
            <div *ngIf="step['latex']" [mathjax]>
              \( {{step['latex']}} \)
            </div>
          </div>
        </li>
      </ol>
    </div>
  </div>
  <div *ngIf="debug" class="card-footer">
    <ngx-json-viewer [expanded]="false" [json]="box"></ngx-json-viewer>
  </div>
</div>
