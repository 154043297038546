// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// apiUrl: 'https://nlp20.herokuapp.com',

export const environment = {
  production: false,
  staging: false,
  apiUrl: 'http://localhost:3001',
  mathApiUrl: 'https://math-php.herokuapp.com',
  gtagId: '',
  firebaseConfig: {
    apiKey: 'AIzaSyCzVx5wQW8XgpUHh7JrX6dj0clnmZob-Gg',
    authDomain: 'cothema-nlp.firebaseapp.com',
    databaseURL: 'https://cothema-nlp.firebaseio.com',
    projectId: 'cothema-nlp',
    storageBucket: 'cothema-nlp.appspot.com',
    messagingSenderId: '999160855321',
    appId: '1:999160855321:web:09cabb0eceab4952206388',
    measurementId: 'G-0H6RRDW9LJ'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
